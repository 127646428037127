import { environment } from '@env/environment';

export const holidayAllowanceRoutes = {
  manageHolidayAllowanceEntitlements: '/admin-hub/holiday-allowances/entitlements',
  manageHolidayAllowanceBalance: '/admin-hub/holiday-allowances/balance'
};

const apiRoot = `${environment.employeeHubApiPaths.employeeLeave}`;

export const holidayAllowanceApiRoutes = {
  holidayAllowance: `${apiRoot}/holiday-allowance`,
  holidayBalance: `${apiRoot}/holiday-balance`,
  defaultHolidayScheme: `${apiRoot}/default-holiday-scheme`
};
