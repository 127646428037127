<ng-template #popContent>
  <div *ngIf="header !== undefined" class="popover-header">
    <b>{{ header }}</b>
  </div>
  <div [ngClass]="{ 'popover-body': true, 'display-above': preferDisplayAbove }">
    <ng-content></ng-content>
  </div>
</ng-template>

<div *ngIf="enabled && matIcon" class="popover-text-icon clickable" [ngClass]="'icon-' + popoverClass">
  <mat-icon
    [placement]="placement"
    class="icon-default popover-icon"
    container="body"
    [popoverClass]="'icon-popover ' + popoverClass"
    [ngbPopover]="popContent"
    [triggers]="onHover ? 'mouseenter:mouseleave' : 'click'"
  >
    {{ matIcon }}
  </mat-icon>
</div>

<app-a
  *ngIf="enabled && linkText"
  [placement]="placement"
  [popoverClass]="'link-popover ' + popoverClass"
  container="body"
  [ngbPopover]="popContent"
  [triggers]="onHover ? 'mouseenter:mouseleave' : 'click'"
>
  {{ linkText }}
</app-a>

<div
  *ngIf="!linkText && !matIcon"
  [placement]="placement"
  [popoverClass]="'link-popover ' + popoverClass"
  container="body"
  [ngbPopover]="popContent"
  [triggers]="enabled ? (onHover ? 'mouseenter:mouseleave' : 'click') : ''"
  [positionTarget]="positionTarget"
>
  <ng-content select="[popover-source]"></ng-content>
</div>
