import { MatDialogConfig } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { ModalOptions } from '../modal-options';
import { AbstractModalService } from '@design/modals/abstract-modal.service';

/**
 * Service for coordinating mat dialog actions between the {@link FormModalActivationComponent} and the housed component
 */
@Injectable({ providedIn: 'root' })
export class FormModalService extends AbstractModalService {
  openFormModal(modalOptions: ModalOptions): void {
    this.modalOptions = modalOptions;
    const componentType = modalOptions.componentType;
    if (!componentType) throw new Error('Must specify a componentType in the route\'s "data" object property.');

    const dialogConfig = <MatDialogConfig>{
      maxHeight: '',
      maxWidth: '',
      disableClose: modalOptions.allowKeyboardClose !== true,
      panelClass: ['form-modal', `size-${modalOptions.modalSize ?? 'standard'}`, `level-${modalOptions.modalLevel ?? 'primary'}`],
      enterAnimationDuration: '600ms',
      data: modalOptions
    };

    this.setDialogRef(this.dialog.open(componentType, dialogConfig));
  }
}
