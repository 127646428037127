import { inject, Injectable } from '@angular/core';
import { take } from 'rxjs';
import { NonStandardModalOptions } from './non-standard-modal-options';
import { CssBreakpointsService } from '@design/layout/css-breakpoints.service';
import { AbstractModalService } from '@design/modals/abstract-modal.service';

/**
 * Service for coordinating mat dialog actions between the {@link StandardModalEntryComponent} and the housed component
 * LOCKED FOR EDIT - EXTEND ONLY INTO SPECIALISED SUB-TYPES
 */
@Injectable({ providedIn: 'root' })
export class NonStandardModalService extends AbstractModalService {
  private breakpointsService = inject(CssBreakpointsService);
  protected modalOptions: NonStandardModalOptions;

  openDialog(modalOptions: NonStandardModalOptions): void {
    this.modalOptions = modalOptions;
    const componentType = modalOptions.componentType;
    if (!componentType) throw new Error('Must specify a componentType in the route\'s "data" object property.');

    const panelClasses = modalOptions.panelClass ? [modalOptions.panelClass, modalOptions.modalType] : [];
    if (modalOptions.fullScreen) {
      panelClasses.push(...['animate__animated', 'animate__slideInRight']);
    }

    this.breakpointsService.breakpoints$.pipe(take(1)).subscribe((bp) => {
      const width = bp.isSmallScreen ? '100vw' : modalOptions.dimensions.width;
      const dialogRef = this.dialog.open(componentType, {
        width,
        minWidth: 320,
        maxWidth: width,
        height: modalOptions.dimensions.height,
        disableClose: true,
        data: modalOptions,
        panelClass: panelClasses
      });

      if (this.modalOptions && this.modalOptions.modalType === 'Message') this.messageDialogRef = dialogRef;
      else this.setDialogRef(dialogRef);
    });
  }

  closeModal() {
    this.router.navigate(['', { outlets: { modal: null } }], { queryParamsHandling: 'preserve' }).then();

    if (this.modalOptions && this.modalOptions.fullScreen) {
      this.dialogRef.removePanelClass('animate__slideInRight');
      this.dialogRef.addPanelClass('animate__slideOutRight');
      setTimeout(() => {
        this.dialogRef.close();
      }, 1000);
    } else {
      this.dialogRef.close();
    }
  }

  closeMessageModal() {
    this.router.navigate(['', { outlets: { message: null } }], { queryParamsHandling: 'preserve' }).then();
    this.messageDialogRef.close();
  }
}
