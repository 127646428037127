import { Inject, Injectable } from '@angular/core';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { TopNavigationLink } from '@app/app-layout/header/product-navigation/navigation-bar/navigation-bar';
import { holidayAllowanceRoutes } from '@app/admin-hub/holiday-allowances/holiday-allowance-routes';
import { adminHubAbsenceRoutes } from '@app/admin-hub/absence/absence-routes';
import { FeatureFlagService, FeatureFlagsServiceInjectionToken } from '@feature-flags/feature-flag.service';

@Injectable({ providedIn: 'root' })
export class AdminHubNavigationService {
  constructor(public activeSecurity: ActiveSecurityContextStateService) {}

  fetchAdminHubNavigation = (): TopNavigationLink[] => {
    const navigationLinks: TopNavigationLink[] = [];

    if (this.activeSecurity.hasOrganisationAuthorityTo('AccessAdminHub')) {
      navigationLinks.push({
        label: 'Calendar',
        route: adminHubAbsenceRoutes.calendar,
        anyFeaturePackPermissions: ['AccessSickness'],
        anyOrganisationPermissions: ['EditEmployeeSickness', 'AccessAbsenceCalendar'],
        feature: 'AdminHubCalendar'
      });

      if (this.activeSecurity.hasOrganisationAuthorityTo('AccessAdminHubHolidays')) {
        navigationLinks.push({
          label: 'Manage holidays',
          route: holidayAllowanceRoutes.manageHolidayAllowanceEntitlements,
          anyOrganisationPermissions: ['AccessAdminHubHolidays'],
          feature: 'HolidayAllowanceDashboard'
        });
      }

      navigationLinks.push({
        label: 'Manage absence',
        route: adminHubAbsenceRoutes.settings,
        anyFeaturePackPermissions: ['AccessAbsence'],
        anyOrganisationPermissions: ['EditEmployeeSickness'],
        feature: 'Settings'
      });
    }

    return navigationLinks;
  };
}
