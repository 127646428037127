/**
 * Wraps a discrete collection into a generic representation that can be used in generic ui-components like drop lists
 */
export abstract class AbstractDataset<TItem, TValue> {
  public constructor(public items: TItem[]) {}

  abstract getValue(item: TItem): TValue;

  abstract getLabel(item: TItem): string;

  getFormValue(values: any[]): any {
    return [...values]; // new array for models purposes
  }

  getInitialItems(formValue: any): any {
    if (Array.isArray(formValue)) {
      return this.items.filter((i) => this.contains(formValue, i));
    } else {
      return this.items.filter((i) => this.compare(formValue, i));
    }
  }

  compare(option: TItem, selected: TItem): boolean {
    return option === selected;
  }

  contains = (source: TItem[], item: TItem) => source.some((target) => this.compare(item, target));
}
