import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CintraProductRouteData } from '../active-cintra-product.service';
import { AdminHubFeature } from '@app/app-features';
import {
  OrganisationAuthorisedRouteGuard,
  getAnyOrganisationPermissions
} from '@security/authorization/organisation-authorised.route-guard';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { adminHubAbsenceRoutes } from '@app/admin-hub/absence/absence-routes';
import { holidayAllowanceRoutes } from '@app/admin-hub/holiday-allowances/holiday-allowance-routes';

export const adminHubRouteData: CintraProductRouteData = {
  cintraProduct: 'AdminHub'
};

export interface AdminHubFeatureRouteData {
  feature: AdminHubFeature;
}

export const getAdminHubLandingRoute = (activeSecurity: ActiveSecurityContextStateService) => {
  if (activeSecurity.hasFeaturePackAuthorityTo('AccessSickness') && activeSecurity.hasOrganisationAuthorityTo('EditEmployeeSickness'))
    return adminHubAbsenceRoutes.calendar;

  if (
    activeSecurity.hasFeaturePackAuthorityTo('AccessEmployeeHubHolidays') &&
    activeSecurity.hasOrganisationAuthorityTo('EditEmployeeLeave')
  )
    return holidayAllowanceRoutes.manageHolidayAllowanceEntitlements;
};

const routes: Routes = [
  {
    path: '',
    canActivate: [OrganisationAuthorisedRouteGuard],
    runGuardsAndResolvers: 'always',
    data: <CintraProductRouteData>{
      ...getAnyOrganisationPermissions('AccessAdminHub'),
      ...adminHubRouteData
    },
    children: [
      {
        path: 'holiday-allowances/:allowanceType',
        data: <AdminHubFeatureRouteData>{
          feature: 'HolidayAllowanceDashboard'
        },
        loadChildren: () =>
          import('./holiday-allowances/holiday-allowances-dashboard.module').then((m) => m.HolidayAllowancesDashboardModule)
      },
      {
        path: 'calendar',
        data: <AdminHubFeatureRouteData>{
          feature: 'AdminHubCalendar'
        },
        loadChildren: () => import('@app/admin-hub/absence/absence-dashboard.module').then((m) => m.AbsenceDashboardModule)
      },
      {
        path: 'absence-settings',
        data: <AdminHubFeatureRouteData>{
          feature: 'Settings'
        },
        loadChildren: () =>
          import('@app/admin-hub/absence/configuration/employee-leave-config.module').then((m) => m.EmployeeLeaveConfigModule)
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminHubRoutingModule {}
