<div *ngIf="dialogData.showIcon" style="text-align: center">
  <app-warning-icon></app-warning-icon>
</div>

<title data-tid-alert-title mat-dialog-title *ngIf="!dialogData.displayTitleAsHeader">{{ dialogData.title }}</title>
<div *ngIf="dialogData.displayTitleAsHeader" class="title" style="padding-top: 1rem; text-align: center">
  <app-h4>{{ dialogData.title }}</app-h4>
</div>

<div mat-dialog-content [innerHTML]="sanitizeMessage(dialogData.message)"></div>

<div mat-dialog-actions align="center">
  <div mat-dialog-actions align="center">
    <button data-tid-ok-button mat-flat-button color="primary" class="alert-button" mat-button mat-dialog-close="CONFIRMED" cdkFocusInitial>
      <span class="button-label">OK</span>
    </button>
  </div>
</div>
