import { Injectable } from '@angular/core';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { HolidayApproverSummary } from 'src/app/employee-hub/employee-leave/leave-approvals';
import { NavigationLink, TopNavigationLink } from '@app/app-layout/header/product-navigation/navigation-bar/navigation-bar';
import { employeeHubRoutes } from '@app/employee-hub/employee-hub-routes';

@Injectable({ providedIn: 'root' })
export class EmployeeHubNavigationService {
  constructor(public activeSecurity: ActiveSecurityContextStateService) {}

  fetchEmployeeHubNavigation = (approverSummary: HolidayApproverSummary): TopNavigationLink[] => {
    const navigationLinks: TopNavigationLink[] = [];

    navigationLinks.push({
      label: 'Dashboard',
      route: 'employee-hub/dashboard',
      feature: 'EmployeeHubDashboard',
      anyEmploymentFeaturePackPermissions: ['AccessEmployeeHub']
    });

    if (this.activeSecurity.hasEmploymentFeaturePackAuthorityTo('AccessEmployeeHubHolidays'))
      navigationLinks.push({
        label: 'Calendar',
        route: 'employee-hub/my-calendar',
        feature: 'Holidays',
        anyEmploymentFeaturePackPermissions: ['AccessEmployeeHubHolidays']
      });

    if (this.activeSecurity.hasEmploymentFeaturePackAuthorityTo('AccessEmployeeHubDocuments'))
      navigationLinks.push({
        label: 'Payslips',
        route: 'employee-hub/payslips',
        feature: 'Payslips',
        anyEmploymentFeaturePackPermissions: ['AccessEmployeeHubDocuments']
      });

    if (this.activeSecurity.hasEmploymentFeaturePackAuthorityTo('AccessEmployeeHubDocuments'))
      navigationLinks.push({
        label: 'Documents',
        route: 'employee-hub/documents',
        feature: 'HubDocuments',
        anyEmploymentFeaturePackPermissions: ['AccessEmployeeHubDocuments']
      });

    if (this.activeSecurity.hasEmploymentFeaturePackAuthorityTo('AccessEmployeeHubPersonalDetails'))
      navigationLinks.push({
        label: 'Profile',
        route: 'employee-hub/my-profile',
        feature: 'MyProfile',
        anyEmploymentFeaturePackPermissions: ['AccessEmployeeHubPersonalDetails']
      });

    if (approverSummary.isHolidayApprover && this.activeSecurity.hasEmploymentFeaturePackAuthorityTo('AccessEmployeeHubHolidays')) {
      const approvalSectionLinks = <NavigationLink[]>[
        {
          label: 'Requests',
          route: employeeHubRoutes.holidayApprovalsRequests,
          counter: approverSummary.openHolidayRequestCount + (approverSummary.openAbsenceRequestCount ?? 0),
          feature: 'HolidayApprovals',
          section: 'HolidayRequests',
          anyEmploymentFeaturePackPermissions: ['AccessEmployeeHubHolidays']
        }
      ];

      approvalSectionLinks.push({
        label: 'Calendar',
        route: employeeHubRoutes.holidayApprovalsCalendar,
        feature: 'HolidayApprovals',
        section: 'ApprovalCalendar',
        anyEmploymentFeaturePackPermissions: ['AccessEmployeeHubHolidays']
      });

      approvalSectionLinks.push({
        label: 'Entitlements',
        route: employeeHubRoutes.holidayApprovalsEntitlements,
        feature: 'HolidayApprovals',
        section: 'ApprovalEntitlements',
        anyEmploymentFeaturePackPermissions: ['AccessEmployeeHubHolidays']
      });

      approvalSectionLinks.push({
        label: 'History',
        route: employeeHubRoutes.holidayApprovalsHistory,
        feature: 'HolidayApprovals',
        section: 'ApprovalHistory',
        anyEmploymentFeaturePackPermissions: ['AccessEmployeeHubHolidays']
      });

      const approvalsLink = <TopNavigationLink>{
        label: 'Approvals',
        feature: 'HolidayApprovals',
        counter: approverSummary.openHolidayRequestCount + (approverSummary.openAbsenceRequestCount ?? 0),
        sectionLinks: approvalSectionLinks
      };
      navigationLinks.push(approvalsLink);
    }

    return navigationLinks;
  };
}
