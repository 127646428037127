import { Injectable } from '@angular/core';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { NavigationLink, TopNavigationLink } from '@app/app-layout/header/product-navigation/navigation-bar/navigation-bar';
import { userRoutes } from '@security/users/user-routes';
import { organisationRoutes } from '@security/organisations/organisation-routes';
import { customReportTypesRoutes } from '@app/custom-reports-admin/custom-report-types-routes';
import { integrationsRoutes } from '@app/integrations-admin/integrations-routes';
import { HashMap } from '@datorama/akita';
import { FeatureFlagType } from '@feature-flags/feature-flag.service';
import { cintraHrLogRoutes } from '@app/hrlog/cintrahrlog-routes';
import { designSystemRoutes } from '@app/design-system/design-system-routes';
import { Member } from '@security/users/models/member';

@Injectable({ providedIn: 'root' })
export class CintraPayNavigationService {
  constructor(public activeSecurity: ActiveSecurityContextStateService) {}

  fetchCintraPayNavigation = (featureFlags: HashMap<FeatureFlagType>, hrEnabled: boolean, member: Member): TopNavigationLink[] => {
    const navigationLinks: TopNavigationLink[] = [];

    if (this.activeSecurity.hasOrganisationAuthorityTo('AccessPayrolls')) {
      navigationLinks.push({
        label: 'Payrolls',
        route: 'employers',
        feature: 'Payrolls',
        anyOrganisationPermissions: ['AccessPayrolls'],
        execute: (data?: any) => this.activeSecurity.navigatePayrolls()
      });
    }

    if (this.activeSecurity.hasOrganisationAuthorityTo('AccessPayrollReports')) {
      navigationLinks.push({
        label: 'Reports',
        route: 'payrolls/reports',
        feature: 'Reports',
        anyOrganisationPermissions: ['AccessPayrollReports']
      });
    }

    if (this.activeSecurity.hasOrganisationAuthorityTo('AccessGroups') && this.activeSecurity.activeMembership.groupsEnabled) {
      navigationLinks.push({
        label: 'Groups',
        route: 'access-control/groups',
        feature: 'Groups',
        anyOrganisationPermissions: ['AccessGroups'],
        enabledAtOrganisation: member.groupsEnabled
      });
    }

    if (this.activeSecurity.hasOrganisationAuthorityTo('AccessMessaging') && this.activeSecurity.activeMembership.messagingEnabled) {
      navigationLinks.push({
        label: 'Messaging',
        route: 'messaging/inbox',
        feature: 'Messaging',
        enabledAtOrganisation: member.messagingEnabled,
        anyOrganisationPermissions: ['AccessMessaging']
      });
    }

    if (this.activeSecurity.hasOrganisationAuthorityTo('AccessPayrolls') && this.activeSecurity.activeMembership.cintraIqAuroraEnabled) {
      const cloudIQURL = 'https://payrollengine.cintra.co.uk/RDWeb/webclient';

      navigationLinks.push({
        label: 'Cloud IQ',
        route: cloudIQURL,
        openInNewTab: true,
        feature: 'Cloud IQ',
        anyOrganisationPermissions: ['AccessPayrolls']
      });
    }

    if (this.activeSecurity.hasOrganisationAuthorityTo('AccessPayrolls') && hrEnabled) {
      navigationLinks.push({
        label: 'CintraHR log',
        route: cintraHrLogRoutes.hub,
        feature: 'CintraHrLog',
        anyOrganisationPermissions: ['AccessPayrolls']
      });
    }

    let settings: NavigationLink[] = [];

    if (this.activeSecurity.hasOrganisationAuthorityTo('EditUsers')) {
      settings.push({
        label: 'Manage users',
        route: userRoutes.hub,
        feature: 'Settings',
        section: 'ManageUsers',
        anyOrganisationPermissions: ['EditUsers']
      });
    }

    if (
      this.activeSecurity.hasFeaturePackAuthorityTo('AccessPaySpines') &&
      this.activeSecurity.hasOrganisationAuthorityTo('AccessPositionManagement')
    ) {
      settings.push({
        label: 'Position management',
        route: 'positions/positions-hub',
        feature: 'Settings',
        section: 'PositionManagement',
        anyOrganisationPermissions: ['AccessPositionManagement']
      });
    }

    if (this.activeSecurity.hasApplicationAuthorityTo('EditCintraEmployees')) {
      settings.push({
        label: 'Manage Cintra users',
        route: 'users/cintra-hub',
        feature: 'Settings',
        section: 'ManageCintraUsers',
        anyApplicationPermissions: ['EditCintraEmployees']
      });
    }

    if (this.activeSecurity.hasApplicationAuthorityTo('EditOrganisations')) {
      settings.push(
        {
          label: 'Manage organisations',
          route: organisationRoutes.hub,
          feature: 'Settings',
          section: 'ManageOrganisations',
          anyApplicationPermissions: ['EditOrganisations']
        },
        {
          label: 'Cintra Admin Area',
          route: 'admin-area/hub',
          feature: 'Settings',
          section: 'CintraAdminArea',
          anyApplicationPermissions: ['EditOrganisations']
        }
      );
    }

    if (this.activeSecurity.hasApplicationAuthorityTo('CreateCustomReports')) {
      settings.push({
        label: 'Manage Custom Reports',
        route: customReportTypesRoutes.hub,
        feature: 'Settings',
        section: 'ManageCustomReports',
        anyApplicationPermissions: ['CreateCustomReports']
      });
    }

    if (this.activeSecurity.hasOrganisationAuthorityTo('ManageIntegrations')) {
      settings.push({
        label: 'Manage integrations',
        route: integrationsRoutes.hub,
        feature: 'Settings',
        section: 'ManageIntegrations',
        anyOrganisationPermissions: ['ManageIntegrations']
      });
    }

    if (this.activeSecurity.isCintraAdmin()) {
      settings.push({
        label: 'Design system',
        route: designSystemRoutes.home,
        feature: 'Settings',
        featureFlagBooleanValue: 'on',
        section: 'DesignSystem',
        anyApplicationPermissions: ['EditOrganisations'],
        featureFlag: 'designSystemFeature'
      });
    }

    if (featureFlags['dynamicReporting'] === true && this.activeSecurity.hasApplicationAuthorityTo('ManageReporting')) {
      settings.push({
        label: 'Live reporting',
        route: 'live-reporting/hub',
        feature: 'Settings',
        section: 'LiveReporting',
        anyApplicationPermissions: ['ManageReporting']
      });
    }

    if (featureFlags['equifaxOptOut'] === true && this.activeSecurity.hasOrganisationAuthorityTo('EquifaxDataConsent')) {
      settings.push({
        label: 'Organisation settings',
        route: organisationRoutes.settings,
        feature: 'Settings',
        section: 'OrganisationSettings',
        anyOrganisationPermissions: ['EquifaxDataConsent']
      });
    }

    if (settings.length > 0) {
      navigationLinks.push({
        label: 'Settings',
        route: '/settings/',
        sectionLinks: settings,
        feature: 'Settings'
      });
    }

    return navigationLinks;
  };
}
