import { Injectable, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { combineLatest, filter, map } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { createStore, select, setProps, withProps } from '@ngneat/elf';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActionArea, ActionConfig } from '@design/buttons/action-config';
import { BurgerBar, emptyAppLayout, RightSidebar } from './app-layout';
import { ChangePasswordDialogComponent } from '@auth-n/change-password/change-password-dialog.component';
import { ChangeDetailsDialogComponent } from '@security/users/change-details/change-details-dialog.component';
import { ActiveSecurityContextStateService } from '@security/active-security/active-security-context.state-service';
import { AuthenticationService } from '@auth-n/authentication.service';
import { HolidayApproverSummaryStateService } from 'src/app/employee-hub/employee-leave/leave-approvals';
import { EquifaxDisclosureAcknowledgementDataProvider } from '@app/equifax/equifax-disclosure-acknowledgement-data-provider';
import { BeamerService } from '@app/app-layout/header/beamer.service';
import { ActiveCintraProductService } from '@app/active-cintra-product.service';
import { CintraProduct } from '@app/cintra-product';

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class AppLayoutStateService implements OnDestroy {
  private readonly store = createStore({ name: 'app-layout' }, withProps(emptyAppLayout));

  accountMenu$ = this.store.pipe(select((state) => state.accountMenu));
  burgerBar$ = this.store.pipe(select((state) => state.burgerBar));
  rightSidebar$ = this.store.pipe(select((state) => state.rightSidebar));
  holidayApproverSummary$ = this.store.pipe(select((state) => state.holidayApproverSummary));
  hasAcknowledgedEquifaxDisclosure$ = this.store.pipe(select((state) => state.hasAcknowledgedEquifaxDisclosure));

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    private activeSecurity: ActiveSecurityContextStateService,
    private holidayApproverSummaryStateService: HolidayApproverSummaryStateService,
    private equifaxDisclosureAcknowledgementDataProvider: EquifaxDisclosureAcknowledgementDataProvider,
    public productService: ActiveCintraProductService
  ) {
    combineLatest([activeSecurity.activeUser$, productService.activeProduct$])
      .pipe(untilDestroyed(this))
      .subscribe(([activeUser, activeProduct]) => {
        this.setAccountMenu(activeProduct);
      });

    this.setHolidayApproverSummary();
    this.setHasAcknowledgedEquifaxDisclosure();
  }

  private setHolidayApproverSummary() {
    // required by both the burger bar and the employee hub top nav...
    this.activeSecurity.activeMembership$
      .pipe(
        untilDestroyed(this),
        filter((m) => !!m),
        map((m) => m.organisationId),
        distinctUntilChanged()
      )
      .subscribe((m) => {
        if (this.activeSecurity.canAccessHolidays()) {
          this.holidayApproverSummaryStateService.fetchEntity();
        } else {
          this.holidayApproverSummaryStateService.entity = emptyAppLayout.holidayApproverSummary;
        }
      });
    this.holidayApproverSummaryStateService.entity$
      .pipe(untilDestroyed(this))
      .subscribe((holidayApproverSummary) => this.store.update(setProps({ holidayApproverSummary })));
  }

  private getAccountMenuActions(product: CintraProduct): ActionArea {
    let actions: ActionConfig[] = [];

    if (product === 'EmployeeHub' || product === 'Payroll' || product === 'Bureau') {
      actions.push({
        label: "What's new?",
        buttonClass: 'beamer-button',
        buttonId: BeamerService.elementIdForBeamer,
        showOnTopNav: false
      });
    }

    actions.push(
      {
        label: 'Change Password',
        execute: () => this.dialog.open(ChangePasswordDialogComponent),
        showOnTopNav: true
      },
      {
        label: 'Change Phone Number',
        execute: () => this.dialog.open(ChangeDetailsDialogComponent),
        showOnTopNav: true
      },
      {
        label: 'Sign Out',
        execute: () => this.authenticationService.signOutAndRedirect(),
        showOnTopNav: true
      }
    );

    return {
      actions
    };
  }

  private setAccountMenu(product: CintraProduct) {
    const accountMenu = this.getAccountMenuActions(product);
    this.store.update(setProps({ accountMenu }));
  }

  private setHasAcknowledgedEquifaxDisclosure() {
    this.activeSecurity.activeMembership$
      .pipe(
        untilDestroyed(this),
        filter((m) => !!m),
        map((m) => m.organisationId),
        distinctUntilChanged()
      )
      .subscribe((m) => {
        this.equifaxDisclosureAcknowledgementDataProvider.hasAcknowledgedEquifaxDisclosure$().subscribe((response) => {
          this.store.update(setProps({ hasAcknowledgedEquifaxDisclosure: response }));
        });
      });
  }

  updateBurgerBar(updates: Partial<BurgerBar>) {
    const { burgerBar } = this.store.getValue();
    this.store.update(setProps({ burgerBar: { ...burgerBar, ...updates } }));
  }

  updateRightSidebar(updates: Partial<RightSidebar>) {
    const { rightSidebar } = this.store.getValue();
    this.store.update(setProps({ rightSidebar: { ...rightSidebar, ...updates } }));
  }

  getBurgerBar(): BurgerBar {
    return this.store.getValue().burgerBar;
  }

  getRightSidebar(): RightSidebar {
    return this.store.getValue().rightSidebar;
  }

  ngOnDestroy(): void {
    this.store.destroy();
  }
}
