import { CostCode } from '@shared-models';

export function formatCostCodes(item: { costCodes: CostCode[] }): string {
  return item.costCodes
    ? item.costCodes
        .map((costCode) => costCode.key)
        .sort((a, b) => (a > b ? 1 : -1))
        .join(', ')
    : '';
}

export function padNumber(num: number, targetLength: number): string {
  return num.toString().padStart(targetLength, '0');
}

export function convertDecimalHoursToHoursAndMinutes(duration: number): string {
  return `${getHoursOnly(duration)}h ${getMinutesFromHourDecimal(duration)}m`;
}

export function getHoursOnly(duration: number): string {
  const fixedDuration = (duration - (duration % 1)).toFixed();
  return fixedDuration.padStart(2, '0');
}

export const getDayBasedDurationString = (durationInDays: number): string => `${durationInDays} d`;

const oClock = '00',
  quarterPast = '15',
  halfPast = '30',
  quarterTo = '45';

export function getMinutesFromHourDecimal(duration: number): string {
  const durationFraction = parseFloat(duration.toFixed(2)) % 1;

  switch (durationFraction) {
    case 0:
      return oClock;
    case 0.25:
      return quarterPast;
    case 0.5:
      return halfPast;
    case 0.75:
      return quarterTo;
  }
}
