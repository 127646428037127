<app-form-label *ngIf="label" [requiredField]="requiredField">{{ label }}</app-form-label>
<div class="wrapper">
  <mat-datepicker panelClass="cintra-date-picker" #picker></mat-datepicker>
  <mat-datepicker-toggle matPrefix [for]="picker" [disableRipple]="true"></mat-datepicker-toggle>
  <input
    matInput
    tabindex="0"
    [matDatepicker]="picker"
    [placeholder]="placeholder"
    [formControl]="formControl"
    [required]="requiredField"
    [min]="minDate"
    [max]="maxDate"
  />
</div>
<mat-error *ngIf="formControl?.hasError('required')">Required</mat-error>
